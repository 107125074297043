import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";


const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
   
      const response = await axios.get("https://patrik.onrender.com/transactions");
      setTransactions(response.data);
    
  };


  const filterTransactions = () => {
    if (startDate && endDate) {
      const filteredTransactions = transactions.filter((data) => {
        const transactionDate = new Date(data.timestamp);
        return (
          transactionDate >= startDate.setHours(0, 0, 0, 0) &&
          transactionDate <= endDate.setHours(23, 59, 59, 999)
        );
      });
      return filteredTransactions;
    } else {
      return transactions;
    }
  };

  return (
    <Container fluid>
       <Row className="mt-5">
          <Col md="12">
            <Card >
              <div className="card-header ">
                <strong>Zgjidh Daten</strong>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="m-2">Nga  : </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="m-2">Deri : </label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover mt-6">
            <Card.Header>
              <Card.Title as="h4"><strong>Veprimet</strong></Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Veprimi</th>
                    <th>Monedha</th>
                    <th>Shuma</th>
                    <th>Kursi</th>
                    <th>Rezultati</th>
                    <th>Data/Ora</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filterTransactions().map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>{transaction.action}</td>
                      <td>{transaction.currency}</td>
                      <td>{transaction.amount}</td>
                      <td>{parseFloat(transaction.currency_rate).toFixed(2)}</td>
                      <td>{transaction.result}</td>
                      <td>{formatDate(transaction.timestamp)}</td>
                      <td>
                        <Link
                          to={`/veprimet/edit/${transaction.id}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionList;
