import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPerson,  IoBarChartSharp  , IoLogOut, IoAnalyticsSharp ,IoCalculatorSharp , IoListSharp , } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <aside className="menu pl-5 has-shadow">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/dashboard"}>
              <IoAnalyticsSharp /> Kurset
            </NavLink>
          </li>
          <li>
            <NavLink to={"/kurset"}>
              <IoBarChartSharp/>Ndrysho 
            </NavLink>
          </li>
          <li>
            <NavLink to={"/konverto"}>
              <IoCalculatorSharp />Konverto
            </NavLink>
          </li>
          <li>
            <NavLink to={"/veprimet"}>
              <IoListSharp  />Veprimet
            </NavLink>
          </li>
        </ul>
        
         
            
            <ul className="menu-list">
              <li>
                <NavLink to={"/llogaria"}>
                  <IoPerson /> Llogaria
                </NavLink>
              </li>
            </ul>
          
      

        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <button onClick={logout} className="button is-white">
              <IoLogOut /> Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
