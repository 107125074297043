import React, { useState, useEffect } from "react";
import axios from "axios";
import "./fatura.css";

const FormAddTransaction = () => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [amount, setAmount] = useState("");
  const [action, setAction] = useState("buy");
  const [currencyRate, setCurrencyRate] = useState(0);
  const [result, setResult] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [isReadyForPrint, setIsReadyForPrint] = useState(false); // New state variable

  useEffect(() => {
    axios
      .get("https://patrik.onrender.com/currency-rates")
      .then((res) => setCurrencies(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const formattedDateTime = now.toLocaleString("en-GB", options);
      setCurrentDateTime(formattedDateTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currencies.length > 0 && selectedCurrency) {
      axios
        .get(`https://patrik.onrender.com/currency/${selectedCurrency}`)
        .then((res) => {
          const rate =
            action === "buy" ? res.data.buying_rate : res.data.selling_rate;
          setCurrencyRate(rate);
        })
        .catch((err) => console.log(err));
    }
  }, [currencies, selectedCurrency, action]);

  const handleConvert = () => {
    if (!selectedCurrency || !amount || !action) {
      alert("Please select currency, enter amount, and select action.");
      return;
    }
    axios
      .post("https://patrik.onrender.com/transactions", {
        currency: selectedCurrency,
        amount,
        action,
        currencyRate,
      })
      .then((res) => {
        const calculatedResult =
          action === "buy" ? amount * currencyRate : amount * currencyRate;
        const formattedResult = calculatedResult.toLocaleString("en", {
          useGrouping: true,
        });
        setResult(formattedResult);
        setIsReadyForPrint(true); // Set the flag to indicate content is ready for printing
      });
  };

  const handlePrint = () => {
    const printableElement = document.querySelector(".print-section");
    if (printableElement) {
      const originalDisplay = printableElement.style.display;
      printableElement.style.display = "block";

      setTimeout(() => {
        window.print();
        printableElement.style.display = originalDisplay;
      }, 500); // Delay printing to ensure CSS is applied
    }
  };

  const veprimi = action === "buy" ? "Blerje" : "Shitje";

  return (
    <div className="container-fluid vh-100 bg-primary d-flex justify-content-center align-items-center">
      <div className="w-75 w-md-75 bg-white rounded p-3">
        <h2 className="mt-4 mb-3 text-center">Konverto</h2>
        <div className="row gx-3 justify-content-center">
          <div className="row-md-4">
            <label htmlFor="currency" className="form-label">
              Monedha:
            </label>
            <select
              required
              id="currency"
              className="form-select"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              {currencies.map((currency) => (
                <option key={currency.id} value={currency.currency}>
                  {currency.currency}
                </option>
              ))}
            </select>
          </div>

          <div className="row-md-4">
            <label htmlFor="action" className="form-label">
              Veprimi:
            </label>
            <select
              required
              id="action"
              className="form-select"
              value={action}
              onChange={(e) => setAction(e.target.value)}
            >
              <option value="buy">Blerje</option>
              <option value="sell">Shitje</option>
            </select>
          </div>

          {currencyRate !== 0 && (
            <div className="row-md-4">
              <label htmlFor="rate" className="form-label">
                Kursi:
              </label>
              <input
                type="number"
                id="rate"
                className="form-control"
                value={currencyRate}
                onChange={(e) => setCurrencyRate(e.target.value)}
              />
            </div>
          )}

          <div className="row-md-4">
            <label htmlFor="amount" className="form-label">
              Enter Amount:
            </label>
            <input
              required
              type="number"
              id="amount"
              className="form-control"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-md-auto">
            <button className="btn btn-primary" onClick={handleConvert}>
              Convert
            </button>
          </div>
        </div>

        {/* Result card */}
        {isReadyForPrint && (
          <div className="row justify-content-center mt-3">
            <div className="print-section">
              <div class="fatura">
                <div class="receipt_header">
                  <h1>
                    <span>Exchange Patrik</span>
                  </h1>
                  <h2>
                    Adresa: Rr. S. Frasheri Tirane <span>Tel: 042229254</span>
                    <span>NIPT: K61421005O</span>
                  </h2>
                </div>
                <div class="receipt_body">
                  <div class="date_time_con">
                    <div class="date">Data {currentDateTime}</div>
                  </div>
                  <div class="items">
                    <table>
                      <thead>
                        <th>Veprimi</th>
                        <th>Shuma</th>
                        <th>Kursi</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            {veprimi} {selectedCurrency}
                          </td>
                          <td> {amount}</td>
                          <td> {currencyRate}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td>{result} Leke</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <h3>Faleminderit!</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-md-auto">
                <div className="mt-3">
                  <button className="btn btn-primary" onClick={handlePrint}>
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormAddTransaction;
