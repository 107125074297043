import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import USD from "./pic/USD.png";
import EUR from "./pic/EUR.png";
import CHF from "./pic/CHF.png";
import GBP from "./pic/GBP.png";
import CAD from "./pic/CAD.png";
import AUD from "./pic/AUD.png";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";

 // Mapping between currency codes and their corresponding image paths
 const currencyImages = {
  USD: USD,
  EUR: EUR,
  CHF: CHF,
  GBP: GBP,
  CAD: CAD,
  AUD: AUD,
};

const RateList = () => {
  const [currencyRates, setCurrencyRates] = useState([]);

  useEffect(() => {
    getCurrencyRates();
  }, []);

  const getCurrencyRates = async () => {
    const response = await axios.get("https://patrik.onrender.com/currency-rates");
    setCurrencyRates(response.data);
  };

  

  return (
    <Container fluid>
      
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover  mt-6">
              <Card.Header>
                <Card.Title as="h4"> <strong></strong></Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0 ">
                <Table className=" table is-striped is-fullwidth">
                  <thead className="">
                    <tr>
            <th>ID</th>
            <th>Monedha</th>
            <th>Blerje</th>
            <th>Shitje </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currencyRates.map((currency, index) => (
            <tr key={currency.id}>
              <td>{index + 1}</td>
              <td>
                <div>
                  <img
                    src={currencyImages[currency.currency]}
                    alt={currency.currency}
                 />
                  {currency.currency}
                    </div>
                   </td>
                    <td style={{ fontSize: "25px", fontWeight: "bold" }}>{currency.buying_rate}</td>
                    <td style={{ fontSize: "25px", fontWeight: "bold" }}>{currency.selling_rate}</td>

              <td>
                <Link
                  to={`/kurset/edit/${currency.id}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                
              </td>
            </tr>
          ))}
       </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    
  );
};

export default RateList;
