import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import USD from "./pic/USD.png";
import EUR from "./pic/EUR.png";
import CHF from "./pic/CHF.png";
import GBP from "./pic/GBP.png";
import CAD from "./pic/CAD.png";
import AUD from "./pic/AUD.png";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";



const Dashboard = () => {
  
  const [rates, setRates] = useState([]);

  const fetchRates = () => {
    axios
      .get("https://patrik.onrender.com/currency-rates")
      .then((res) => setRates(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchRates(); // Initial fetch
    const intervalId = setInterval(fetchRates, 10000); // Polling every 1 minute
    return () => clearInterval(intervalId); // Cleanup interval
  }, []);

  // Mapping between currency codes and their corresponding image paths
  const currencyImages = {
    USD: USD,
    EUR: EUR,
    CHF: CHF,
    GBP: GBP,
    CAD: CAD,
    AUD: AUD,
  };

  const currentDate = new Date().toLocaleDateString("en-GB");
  const currentTime = new Date().toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });


  return (
    <>
      <Container fluid>
      
        <Row>
        
      
          <Col md="12">
            <Card className="strpied-tabled-with-hover text-center mt-6">
              <Card.Header className="fs-3">
              <strong> {currentDate} {currentTime}</strong>
            
              </Card.Header>
              <Card.Title  className="fs-2 text-right">
              <strong>NO COMMISSION </strong>
                </Card.Title>
              <Card.Body className="table-full-width table-responsive px-0 text-center">
                <Table className="text-center table is-striped is-fullwidth">
                  <thead className="text-center">
                    <tr>
                      <td style={{ fontSize: "18px", fontWeight: "bold" }}>
                        Monedha/Currency
                      </td>
                      <td style={{ fontSize: "18px", fontWeight: "bold" }}>
                        Blerje/We Buy
                      </td>
                      <td style={{ fontSize: "18px", fontWeight: "bold" }}>
                        Shitje/We Sell
                      </td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {rates.map((data) => (
                      <tr key={data.id}>
                        <td style={{ fontSize: "45px", fontWeight: "bold" }}>
                          <div className="fs-1">
                            <img
                              src={currencyImages[data.currency]}
                              alt={data.currency}
                            />
                            {data.currency}
                          </div>
                        </td>
                        <td style={{ fontSize: "45px", fontWeight: "bold" }}>
                          {data.buying_rate.toFixed(1)}
                        </td>
                        <td style={{ fontSize: "45px", fontWeight: "bold" }}>
                          {data.selling_rate.toFixed(1)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

};

export default Dashboard;
