import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Rates from "./pages/Rates";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddTransaction from "./pages/AddTransaction";
import EditRates from "./pages/EditRate";
import Transactions from "./pages/Transactions";
import EditTransaction from "./pages/EditTransaction";
import Llogaria from "./pages/llogaria";
import Tabela from "./pages/Tabela";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/kurset" element={<Rates />} />
          <Route path="/kurset/edit/:id" element={<EditRates />} />
          <Route path="/konverto" element={<AddTransaction />} />
          <Route path="/veprimet" element={<Transactions />} />
          <Route path="/veprimet/edit/:id" element={<EditTransaction />} />
          <Route path="/llogaria" element={<Llogaria />} />
          <Route path="/tabela" element={<Tabela />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
