import React, { useEffect } from "react";

import Kurset from "../components/Welcome";


const Dashboard = () => {


  return (
    
      <Kurset />
    
  );
};

export default Dashboard;
