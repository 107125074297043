import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Card,
    Container,
    Row,
    Col,
    Table,
  } from "react-bootstrap";


const LlogariaList = () => {
    const [startDate, setStartDate] = useState(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0); // Set to the beginning of the day
        return date;
      });
      
      const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999); // Set to the end of the day
        return date;
      });
    const [summaries, setSummaries] = useState([]);

    useEffect(() => {
      axios
      .get(`https://patrik.onrender.com/transaction-summaries?startDate=${startDate}&endDate=${endDate}`)
      .then((res) => setSummaries(res.data))
      .catch((err) => console.log(err));
  }, [startDate, endDate]);
  
    return (
        <Container fluid>

        <Row>
        <Row className="mt-5">
          <Col md="12">
            <Card >
              <div className="card-header ">
                <strong>Zgjidh Daten</strong>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="m-2">Nga : </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="m-2">Deri : </label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
          <Col md="12">
            {summaries.map((summary, index) => (
              <Card key={index} className="mt-6">
                <Card.Header>
                  <Card.Title as="h4"><strong>Monedha: {summary.currency}</strong></Card.Title>
                </Card.Header>
                <Card.Body className="px-0">
                  <Table responsive className="table table-striped mb-4">
                    <thead>
                      <tr>
                        <th>Total Blerje</th>
                        <th>Leke</th>
                        <th>Kursi Mesatar Blerje</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{summary.total_bought} {summary.currency}</td>
                        <td>{summary.total_bought_result}</td>
                        <td>{parseFloat(summary.average_buy_rate).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table responsive className="table table-striped">
                    <thead>
                      <tr>
                        <th>Total Shitje</th>
                        <th>Leke</th>
                        <th>Kursi Mesatar Shitjes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{summary.total_sold} {summary.currency}</td>
                        <td>{summary.total_sold_result}</td>
                        <td>{parseFloat(summary.average_sell_rate).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    );
  };
   
export default LlogariaList;
