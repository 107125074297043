import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditTransaction = () => {
  const [currency, setCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState("");
  const [action, setAction] = useState("");
  const [amount, setAmount] = useState("");
  const [result, setResult] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getTransactionById = async () => {
      try {
        const response = await axios.get(`https://patrik.onrender.com/transactions/${id}`);
        setCurrency(response.data.currency);
        setCurrencyRate(response.data.currency_rate);
        setAction(response.data.action);
        setAmount(response.data.amount);
        setResult(response.data.result);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getTransactionById();
  }, [id]);

  const updateTransaction = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://patrik.onrender.com/transactions/${id}`, {
        currency: currency,
        currency_rate: currencyRate,
        action: action,
        amount: amount,
        result: result,
      });
      navigate("/veprimet");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateTransaction}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Currency</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    placeholder="Currency"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Currency Rate</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={currencyRate}
                    onChange={(e) => setCurrencyRate(e.target.value)}
                    placeholder="Currency Rate"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Action</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                    placeholder="Action"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Amount</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Amount"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Result</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                    placeholder="Result"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditTransaction;
