import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditRates = () => {
  const [selling_rate, SetSelling_rate] = useState("");
  const [buying_rate, SetBuying_rate] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getCurrencyRateById = async () => {
      try {
        const response = await axios.get(
          `https://patrik.onrender.com/currency-rates/${id}`
        );
        SetBuying_rate(response.data.buying_rate);
        SetSelling_rate(response.data.selling_rate);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getCurrencyRateById();
  }, [id]);

  const updateCurrencyRate = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://patrik.onrender.com/currency-rates/${id}`, {
        buying_rate: buying_rate,
        selling_rate: selling_rate,
      });
      navigate("/kurset");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateCurrencyRate}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Blerje</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={buying_rate}
                    onChange={(e) => SetBuying_rate(e.target.value)}
                    placeholder="Product Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Shitje</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={selling_rate}
                    onChange={(e) => SetSelling_rate(e.target.value)}
                    placeholder="Price"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRates;
